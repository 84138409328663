<template>
  <div v-loading="loaders.exportReport" class="m-0 px-3 pt-4 pb-0 pr-2">
    <export-report-modal
      @exportReport="exportPostOpPatients"
    >
    </export-report-modal>
    <div class="d-flex justify-content-between">
      <h1 class="m-0">Post-Op Patients</h1>
      <base-button size="md" id="export"
                   class="float-right mr-4 mt-2 white-outline-button button-font title-btn-padding"
                   @click="showExportReportModal"
                   type="secondary">
        <img alt="Image placeholder" class="mt--1" src="/img/svg/export.svg">
        Export
      </base-button>
    </div>
    <div v-loading="loaders.response" class="mt-4 cardStyle">
      <div class="d-flex justify-content-center justify-content-sm-between flex-wrap my-3 ml-3">
        <div class="d-inline-block">
          <label class="form-control-label">Duration</label>
          <el-select
            class="w-100"
            v-model="request.api.duration"
            placeholder="Select Duration"
            @change="changeDuration"
          >
            <el-option
              class="select-danger"
              :value="0"
              label="All"
              key="-1">
            </el-option>
            <el-option
              v-for="(duration, key) in durations"
              class="select-danger"
              :value="duration.value"
              :label="duration.label"
              :key="key">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-table
        :data="response.postOpPatients"
        row-key="id"
        class="table-responsive table-flush p-3"
        header-row-class-name="thead-light"
        @sort-change="sortMethod"
      >
        <el-table-column label="PATIENT NAME" min-width="140px">
          <template slot-scope="scope">
            <div class="trim-text-overflow">
              <el-tooltip
                class="item" effect="dark"
                :content="hoverPatientInfo(scope.row)"
                placement="bottom-start"
                :visible-arrow="false"
              >
                <router-link :to="{name: 'PatientDetail', params: {id: scope.row.id}}">
                  <span class="color-maroon">{{ scope.row.first_name + ' ' + scope.row.last_name }}</span>
                </router-link>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="SURGERY DATE" prop="surgery_date" min-width="140px" sortable="custom">
          <template slot-scope="scope">
            <span>{{ formatDate(scope.row.surgery_date) }}</span>
          </template>
        </el-table-column>
        <el-table-column label="SINCE SURGERY" min-width="">
          <template slot-scope="scope">
            <span>{{ getDaysSinceSurgery(scope.row.surgery_date) }}</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="row">
        <div class="col-12 my-3 px-4">
          <seminar-surgery-footer :pagination="pagination" @changePage="changePage"
                                  @changedPageLength="changedPageLength"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SeminarSurgeryFooter from "./SeminarSurgeryFooter";
import Helper from "@/util/globalHelpers";
import {Option, Select, Table, TableColumn} from "element-ui";
import moment from "moment-timezone";
import ExportReportModal from "@/views/Pages/Seminar2Surgery/ExportReportModal";
import fileDownload from "@/mixins/fileDownload";

export default {
  name: "PostOpPatients",
  components: {
    SeminarSurgeryFooter,
    ExportReportModal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      loaders: {
        response: false,
        exportReport: false
      },
      request: {
        api: {
          duration: 0,
          sortBy: 'surgery_date',
          sortByDir: 'DESC'
        },
      },
      response: {
        postOpPatients: [],
      },
      pagination: {
        currentPage: 1,
        perPage: 20,
        page: 1,
        lastPage: 1,
      },
      durations: [
        {
          label: '0 - 2 Weeks',
          value: [0, -14]
        },
        {
          label: '2 Weeks - 30 Days',
          value: [-14, -30]
        },
        {
          label: '30 Days - 60 Days',
          value: [-30, -60]
        },
        {
          label: '60 Days - 3 Months',
          value: [-60, -90]
        },
        {
          label: '3 Months - 6 Months',
          value: [-90, -180]
        },
        {
          label: '6 Months - 1 Year',
          value: [-180, -365]
        },
        {
          label: '1 Year - 2 Year',
          value: [-365, -730]
        },
        {
          label: '2 Year  - 3 Year',
          value: [-730, -1095]
        },
        {
          label: '3 Year - 4 Year',
          value: [-1095, -1460]
        },
        {
          label: '4 Year - 5 Years',
          value: [-1460, -1825]
        },
        {
          label: '5 Years and Beyond',
          value: [-1826, null]
        }
      ],
    }
  },
  mounted() {
    this.getPostOpPatients()
  },
  mixins: [
    fileDownload
  ],
  methods: {
    /**
     * Return the payload object
     * @returns {{duration: number, perPage, sortBy: (string|*), sortByDir: (string|*), page}}
     */
    assignParams() {
      let vm = this
      return {
        duration: vm.request.api.duration,
        sortBy: vm.request.api.sortBy,
        sortByDir: vm.request.api.sortByDir,
        page: vm.pagination.page,
        perPage: vm.pagination.perPage
      }
    },

    /**
     * Show the Export Report Modal
     */
    showExportReportModal() {
      this.$store.commit('toggleExportReportModal', true)
    },

    /**
     * Hide the Export Report Modal
     */
    hideExportReportModal() {
      this.$store.commit('toggleExportReportModal', false)
    },

    /**
     * Save the response object
     * @param response
     */
    saveResponse(response) {
      let vm = this
      vm.response.postOpPatients = []
      vm.response.postOpPatients = [...response.data.data.data]
      vm.pagination.currentPage = response.data.data.current_page
      vm.pagination.lastPage = response.data.data.last_page
      vm.pagination.perPage = response.data.data.per_page
      vm.pagination.page = response.data.data.current_page
      vm.pagination.total = response.data.data.total
    },

    /**
     * Get All the Post Op Patients
     */
    getPostOpPatients() {
      let vm = this
      vm.loaders.response = true
      const params = vm.assignParams()
      vm.$store.dispatch('_postOpPatientsReport', params)
        .then(response => {
          vm.saveResponse(response)
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Post-Op Patients Report',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.response = false
        })
    },

    /**
     * Export the Post-Op Patients report
     */
    exportPostOpPatients() {
      let vm = this
      vm.hideExportReportModal()
      vm.loaders.exportReport = true
      const config = {
        responseType: "blob",
        params: {...vm.request.api}
      }
      vm.$store.dispatch('_postOpPatientsExcel', config)
        .then(response => {
          vm.generateExportFileDownload(response, 'Baritastic-All-Post-Op-Patients')
        })
        .catch(error => {
          let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Post-Op Patients Excel',
            message: message
          })
        })
        .finally(() => {
          vm.loaders.exportReport = false
        })
    },

    /**
     * Sorting based on which prop is passed and fetch the post op patients
     * @param prop
     * @param order
     */
    sortMethod({prop, order}) {
      let vm = this
      if (order) {
        vm.request.api.sortBy = prop
        vm.request.api.sortByDir = order == 'ascending' ? 'ASC' : 'DESC'
      } else {
        vm.request.api.sortBy = "surgery_date"
        vm.request.api.sortByDir = 'DESC'
      }
      vm.pagination.page = 1
      vm.getPostOpPatients()
    },

    /**
     * Return the basic information about user
     * @param row
     * @returns {string}
     */
    hoverPatientInfo(row) {
      let returnable = ''
      returnable = (row.hasOwnProperty('first_name') === true && row.first_name !== '' && row.first_name != null) ? returnable + row.first_name : returnable + ''
      returnable = (row.hasOwnProperty('last_name') === true && row.last_name !== '' && row.last_name != null) ? returnable + ' ' + row.last_name : returnable + ''
      returnable = (row.hasOwnProperty('email') === true && row.email !== '' && row.email != null) ? returnable + ', ' + row.email : returnable + ''
      returnable = (row.hasOwnProperty('phone') === true && row.phone !== '' && row.phone != null) ? returnable + ', ' + row.phone : returnable + ''
      if (row.hasOwnProperty('dob') === true && row.dob !== '' && row.dob != null) {
        if (!isNaN(Date.parse(row.dob))) {
          const date = new Date(row.dob)
          returnable = returnable + ', ' + Helper.extractAndFormatDate(date.toDateString(), false)
        }
      } else {
        returnable = returnable + ''
      }
      returnable = (row.hasOwnProperty('mrn_number') === true && row.mrn_number !== '' && row.mrn_number != null) ? returnable + ', ' + row.mrn_number : returnable + ' '
      return returnable
    },

    /**
     * Change page in the listing
     * @param page
     */
    changePage(page) {
      if (this.pagination.page != page) {
        this.pagination.page = page
        this.getPostOpPatients()
      }
    },

    /**
     * Change page length in the listing
     * @param pageLength
     */
    changedPageLength(pageLength) {
      this.pagination.page = 1
      this.pagination.perPage = pageLength
      this.getPostOpPatients()
    },

    /**
     * Return the formatted surgery date
     * @param date
     * @returns {string|string}
     */
    formatDate(date) {
      return date ? moment(date).format('MMM D, YYYY') == 'Invalid date' ? 'Incorrect Date' : moment(date).format('MMM D, YYYY') : ''
    },

    /**
     * Get string of days since surgery
     * @param date
     * @returns {string|*|string}
     */
    getDaysSinceSurgery(date) {
      return date ? this.formatSinceSurgeryDate(moment(date, "YYYY-MM-DD").fromNow()) : ''
    },

    /**
     * Formatting the since surgery string
     * @param date
     * @returns {string|*}
     */
    formatSinceSurgeryDate(date) {
      if (date == 'Invalid date') {
        return ''
      } else if (date.includes('hours ago')) {
        return 'Today'
      } else if (date.includes('days ago')) {
        if (isNaN(parseInt(date.charAt(1)))) {
          return date.replace(date.charAt(0), parseInt(date.charAt(0)) - 1)
        } else {
          return parseInt(date.charAt(1)) == 0 ? (parseInt(date.charAt(0)) - 1) + '9' + date.substr(2)
            : (date.substr(0, 1) + (parseInt(date.charAt(1)) - 1) + date.substr(2))
        }
      } else {
        return date.replace(date.charAt(0), date.charAt(0).toUpperCase())
      }
    },

    /**
     * Duration Filter Select @Change Event
     */
    changeDuration(){
      this.pagination.page = 1;
      this.getPostOpPatients();
    }
  }
}
</script>

<style scoped>

.cardStyle {
  background-color: white;
  border-radius: 6px;
  border: 1px solid #00000000;;
}
</style>
