export default {
  methods: {
    /**
     * Generate Export File download
     * @param response
     * @param fileName
     */
    generateExportFileDownload(response, fileName) {
      const url = URL.createObjectURL(new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName + `.xlsx`)
      document.body.appendChild(link)
      link.click()
    },
  }
}
