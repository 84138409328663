<template>
  <div class="modals">
    <modal @close="hideModal" :show.sync="$store.state.modals.exportReportModal" class="exportReportModal">
      <!-- Notification Modal Header -->
      <template slot="header">
        <h5 class="modal-title">Report</h5>
      </template>
      <!-- Notification Modal Header Finished -->

      <!-- Notification Modal Body -->
      <template slot="default">
        <p class="message-modal-text mb-4 export-report-message">
          This report can take a few minutes to load. Thank you for your patience.
        </p>
      </template>
      <!-- Notification Modal Body Finished -->

      <!-- Notification Modal Footer -->
      <template slot="footer">
        <div class="mt--5">
          <base-button class="btn-purple" type="secondary" @click="hideModal">Cancel</base-button>
          <base-button class="btn-primary-action" type="success" @click="exportReport">Export</base-button>
        </div>
      </template>
      <!-- Notification Modal Footer Finished -->
    </modal>
  </div>
</template>

<script>
export default {
  name: "ExportReportModal",
  methods: {
    exportReport() {
      this.$emit('exportReport')
    },

    hideModal() {
      this.$store.commit('toggleExportReportModal', false)
    }
  }
}
</script>

<style>
.exportReportModal .modal-body {
  padding-top: 0px;
}

.exportReportModal .export-report-message {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.exportReportModal {
  text-align: left;
  font: normal normal normal 14px/19px Open Sans;
  letter-spacing: 0px;
  color: #4D4D4D;
  opacity: 1;
}

.btn-purple {
  color: white;
  background-color: #8898aa;
  border-radius: 9px;
}
</style>
